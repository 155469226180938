import { FormatterDateTime, TranslateOptionType, TranslateVerifyStatus } from "@/common/formatter";
export function formatCommitTime(row) {
    return FormatterDateTime(row.updatedTime, "yyyy-MM-dd hh:mm:ss");
}
export function formatVertifyTime(row) {
    return FormatterDateTime(row.vertifyTime, "yyyy-MM-dd hh:mm:ss");
}
export function formatOption(row) {
    return TranslateOptionType(row.optionType);
}
export function formatVerifyType(row) {
    return TranslateVerifyStatus(row.verifyStatus);
}
export function formatCreateTime(row) {
    return FormatterDateTime(row.createdTime, "yyyy-MM-dd hh:mm:ss");
}
