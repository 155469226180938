import { defineComponent, onMounted } from 'vue';
import { DelWaitGoods, ReturnVertifyGoods, GetVerfifyGoodsInfoById, } from '@/api/GoodsApi';
import { OptionType, VerifyStatus } from '@/common/enum';
import router from '@/router/index';
import { ElMessageBox, ElMessage } from 'element-plus';
import { datas, name, totalCount, queryData, initTypes, pageNum, pageSize, optionType, verifyStatus, loading, goDetailVisible, product, goDetail, } from './WaitQuery';
import { formatCommitTime, formatVertifyTime, formatVerifyType, formatOption, formatCreateTime, } from './WaitFormatter';
import BreadCrumbService from '@/services/BreadCrumb/BreadCrumbService';
import { BreadCrumb } from '@/services/BreadCrumb/BreadCrumb';
import ProDetailDlg from '@/views/GoodsManagement/DetailDlg/Index.vue';
export default defineComponent({
    name: 'wait',
    components: {
        ProDetailDlg,
    },
    setup() {
        onMounted(() => {
            initTypes();
            queryData();
            BreadCrumbService.ClearBreadCrumb();
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb('1', '', '商品管理'));
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb('2', '', '待入库商品'));
        });
        const gotoEdit = (row) => {
            router.push({
                path: '/goods/waitEdit/' + row.id,
                query: {
                    verifyType: row.verifyStatus + '',
                },
            });
        };
        let showDelDlg = false;
        const gotoDel = (row) => {
            ElMessageBox.confirm('确定删除该条记录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then((result) => {
                DelWaitGoods(row.id ? row.id : 0).then((result) => {
                    if (result.isSuccess) {
                        queryData();
                        ElMessage.success({
                            message: '删除成功',
                            type: 'success',
                        });
                    }
                });
            });
        };
        const returnGood = (row) => {
            ElMessageBox.confirm('确定要撤销此次提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then((result) => {
                ReturnVertifyGoods(row.id).then((res) => {
                    if (res.isSuccess) {
                        queryData();
                        ElMessage.success({
                            message: '撤回成功',
                            type: 'success',
                        });
                    }
                });
            });
        };
        const handleCurrentChange = (val) => {
            pageNum.value = val;
            queryData();
        };
        const handleSizeChange = (val) => {
            pageSize.value = val;
            queryData();
        };
        return {
            totalCount,
            pageSize,
            datas,
            name,
            OptionType,
            optionType,
            VerifyStatus,
            verifyStatus,
            queryData,
            formatCommitTime,
            formatVertifyTime,
            formatOption,
            formatVerifyType,
            gotoEdit,
            gotoDel,
            showDelDlg,
            handleCurrentChange,
            formatCreateTime,
            handleSizeChange,
            returnGood,
            loading,
            goDetailVisible,
            product,
            goDetail,
            GetVerfifyGoodsInfoById,
        };
    },
});
