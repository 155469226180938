import { GetWaitGoodsList, QueryParames, WaitGoodsResponse } from "@/api/GoodsApi";
import { OptionType, VerifyStatus } from "@/common/enum";
import CategoryService from "@/services/Goods/CategoryService";
import ShopService from "@/services/Shop/ShopService";
import { reactive, ref } from "vue";
export const datas = reactive(new Array());
export const totalCount = ref(0);
export const pageSize = ref(10);
export const pageNum = ref(1);
export const name = ref("");
export const optionType = ref(OptionType.None);
export const verifyStatus = ref(VerifyStatus.None);
export const allTypes = reactive(new Array());
export const loading = ref(false);
export const goDetailVisible = ref(false);
export const product = reactive(new WaitGoodsResponse());
export function goDetail(row) {
    goDetailVisible.value = true;
    product.id = row.id;
}
export async function queryData() {
    const params = new QueryParames();
    params.pageNum = pageNum.value;
    params.pageSize = pageSize.value;
    params.name = name.value;
    params.verifyStatus = verifyStatus.value;
    params.optionType = optionType.value;
    params.merchantId = ShopService.GetCurrentShop().merchantId;
    loading.value = true;
    const result = await GetWaitGoodsList(params);
    loading.value = false;
    if (result.isSuccess) {
        totalCount.value = result.resultData.total;
        datas.splice(0, datas.length);
        result.resultData.list.forEach((item) => {
            datas.push(item);
        });
    }
}
export function initTypes() {
    const result = CategoryService.GetAllCatagory();
    allTypes.splice(0, allTypes.length);
    result.forEach((item) => {
        allTypes.push(item);
    });
}
